import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Dialog,
} from "@mui/material";
import { useAuth, treatLoginUserInfo } from "../../Main/AuthProvider";
import mailAuth from "../../Main/loginServices/email";
import { useNavigate } from "react-router-dom";
import AppLoginForm from "./AppLoginForm";

const Login = ({ open, onClose }) => {
  let navigate = useNavigate();

  const { dispatch } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Insira um e-mail válido")
        .max(255)
        .required("E-mail é obrigatório"),
      password: Yup.string().max(255).required("Senha é obrigatória"),
    }),
    onSubmit: ({ email, password }, { setFieldError, setSubmitting }) => {
      mailAuth
        .signin({ email, password })
        .then((user) => {
          setSubmitting(false);
          dispatch(treatLoginUserInfo(user));
          onClose();
          navigate("/home", { replace: true });
        })
        .catch((err) => {
          setSubmitting(false);
          setFieldError("email", "E-mail ou senha inválidos");
        });
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <AppLoginForm>
        <Typography variant="h4" gutterBottom marked="center" align="center">
          Entrar
        </Typography>
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="E-mail"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Senha"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body2">
              Não tem uma conta ainda?{" "}
              <Link
                href={process.env.REACT_APP_WHATSAPP_CONTACT_URL}
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: "pointer",
                }}
              >
                Registre-se
              </Link>
            </Typography>
          </form>
        </Container>
      </AppLoginForm>
    </Dialog>
  );
};

export default Login;
