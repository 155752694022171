import React from "react";
import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./AuthProvider";

import Home from "../Pages/Home/Home";
import AppBarButton from "../Pages/Home/AppBarButton";
import Public from "../Pages/Public/Public";
import Main from "./Main";

const AuthCheck = (El, barbuttons) => {
  const { userInfo } = useAuth();
  if (!userInfo.loggedIn) {
    return <Navigate to="/" replace />;
  }
  return (
    <Main BarButtons={barbuttons}>
      <El />
    </Main>
  );
};

const privateRoutes = [
  { element: Home, barbuttons: AppBarButton, path: "/home" },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {privateRoutes.map(({ path, element, barbuttons, ...rest }) => (
          <Route
            key={path}
            path={path}
            element={AuthCheck(element, barbuttons)}
            {...rest}
          />
        ))}

        <Route exact path="/" element={<Public />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
