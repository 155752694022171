import React from "react";
import AppBar from "./AppBar";

const Main = ({ children, BarButtons }) => {
  return (
    <div style={{ fontFamily: "Roboto" }}>
      <AppBar BarButtons={BarButtons} />
      {children}
    </div>
  );
};

export default Main;
