import React, { useState } from "react";
import ProductSmokingHero from "./ProductSmokingHero";
import AppFooter from "./AppFooter";
import ProductHero from "./ProductHero";
import ProductValues from "./ProductValues";
import ProductHowItWorks from "./ProductHowItWorks";
import AppAppBar from "./AppAppBar";
import LoginDialog from "./LoginDialog";

const Public = () => {
  const [loginFormOpen, setLoginFormOpen] = useState(false);
  return (
    <>
      <AppAppBar onOpenForm={() => setLoginFormOpen(true)} />
      <ProductHero />
      <ProductValues />
      <ProductHowItWorks />
      <ProductSmokingHero />
      <AppFooter />
      <LoginDialog
        open={loginFormOpen}
        onClose={() => setLoginFormOpen(false)}
      />
    </>
  );
};

export default Public;
