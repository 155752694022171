import * as React from "react";
import Button from "../../Components/Button";
import Typography from "../../Components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: "url(/images/backgroundImage.jpg)",
        backgroundColor: "#7fc7d9",
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src="/images/backgroundImage.jpg"
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Aumente suas chances de ganhar!
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        Confira os resultados anteriores além de diversos indicadores
        personalizados por um preço acessível
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href={process.env.REACT_APP_WHATSAPP_CONTACT_URL}
        sx={{ minWidth: 200 }}
      >
        Saiba mais
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Aprenda a jogar contra a casa
      </Typography>
    </ProductHeroLayout>
  );
}
