import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from "../../Components/AppBar";
import Toolbar from "../../Components/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAuth, treatLogoutUserInfo } from "../../Main/AuthProvider";
import mailAuth from "../../Main/loginServices/email";
import { useNavigate } from "react-router-dom";

const Login = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

function AppAppBar({ onOpenForm }) {
  const { userInfo, dispatch } = useAuth();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    mailAuth.signout(userInfo.token).then(() => {
      handleClose();
      dispatch(treatLogoutUserInfo());
    });
  };

  const handleRedirectHome = () => {
    handleClose();
    navigate("/home", { replace: true });
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }} />
          <img
            width={30}
            style={{ marginBottom: 6 }}
            src="/images/flameLogo.png"
            alt="logo"
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Blaze Tips
          </Typography>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            {userInfo.loggedIn ? (
              <div>
                <IconButton size="large" onClick={handleMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem disabled>{userInfo.name}</MenuItem>
                  <MenuItem onClick={handleRedirectHome}>
                    Página Inicial
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </div>
            ) : (
              <Button onClick={onOpenForm} sx={Login}>
                {"Login"}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
