import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Chip from "@mui/material/Chip";
import { Tooltip } from "@mui/material";

const colorsPortuguese = {
  0: "Branco",
  1: "Vermelho",
  2: "Preto",
};

const symbolsRepresentation = {
  0: "⚪",
  1: "🔴",
  2: "⚫",
};

const lastTimeWhite = (results) => {
  return results.findIndex((r) => r.color === 0) + 1;
};

const lastTimeRed = (results) => {
  return results.findIndex((r) => r.color === 1) + 1;
};

const lastTimeBlack = (results) => {
  return results.findIndex((r) => r.color === 2) + 1;
};

const ResultsTable = ({ rows, lastUpdate }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography sx={{ flex: "1 1 100%" }} variant="h5" component="div">
            Ultimos resultados
            <Typography variant="subtitle2">
              Atualizado {lastUpdate}s atrás.
            </Typography>
          </Typography>
          <Tooltip title="Quantas rodadas sem sair a cor">
            <Chip
              label={`⚪: ${lastTimeWhite(rows)}`}
              variant="outlined"
              sx={{ mr: 1, backgroundColor: "f5f5f5" }}
            />
          </Tooltip>
          <Tooltip title="Quantas rodadas sem sair a cor">
            <Chip
              label={`🔴: ${lastTimeRed(rows)}`}
              variant="outlined"
              sx={{ mr: 1, backgroundColor: "f5f5f5" }}
            />
          </Tooltip>
          <Tooltip title="Quantas rodadas sem sair a cor">
            <Chip
              label={`⚫: ${lastTimeBlack(rows)}`}
              variant="outlined"
              sx={{ mr: 1, backgroundColor: "f5f5f5" }}
            />
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Cor</TableCell>
                <TableCell align="right">Numero</TableCell>
                <TableCell align="right">Horario</TableCell>
                <TableCell align="right">Seed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.externalId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#fff",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.externalId}
                  </TableCell>
                  <TableCell align="right">
                    {`${colorsPortuguese[row.color]} - ${
                      symbolsRepresentation[row.color]
                    } `}
                  </TableCell>
                  <TableCell align="right">{row.roll}</TableCell>
                  <TableCell align="right">
                    {new Date(row.rollTime).toLocaleTimeString()}
                  </TableCell>
                  <TableCell align="right">{row.server_seed}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ResultsTable;
