import { useEffect, useState, useCallback } from "react";
import ResultsTable from "./ResultsTable";
import { useAuth } from "../../Main/AuthProvider";

function Home() {
  const { userInfo } = useAuth();
  const [results, setResults] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [timeCounter, setTimeCounter] = useState(new Date());

  const updateValues = useCallback(() => {
    fetch(
      `${process.env.REACT_APP_PARSE_URL}/classes/Rolls?limit=200&order=-createdAt`,
      {
        headers: {
          "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
          "X-Parse-Rest-Api-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
          "X-Parse-Session-Token": userInfo.token,
        },
      }
    )
      .then((r) => r.json())
      .then((r) => {
        setResults(r.results);
      });
    setLastUpdate(new Date());
  }, [userInfo.token]);

  //Update every 10 seconds
  useEffect(() => {
    const interval = setInterval(updateValues, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateValues]);

  //Update refresh counter
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounter(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //Load the first data
  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return (
    <div>
      <ResultsTable
        rows={results}
        lastUpdate={parseInt((timeCounter - lastUpdate) / 1000)}
      />
    </div>
  );
}

export default Home;
