const mailAuth = {
  isAuthenticated: false,

  async signin({ email, password }) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_PARSE_URL}/login`, {
        body: JSON.stringify({ username: email, password }),
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
          "X-Parse-Rest-Api-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
          "X-Parse-Revocable-Session": "1",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) return response.json();
          reject(response.statusText);
        })
        .then((response) => {
          mailAuth.isAuthenticated = true;
          resolve({
            id: response.objectId,
            email: email,
            name: email,
            token: response.sessionToken,
          });
        });
    });
  },

  async signout(token) {
    return new Promise((r) => {
      fetch(`${process.env.REACT_APP_PARSE_URL}/logout`, {
        headers: {
          "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
          "X-Parse-Rest-Api-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
          "X-Parse-Session-Token": token,
        },
        method: "POST",
      }).then(() => {
        mailAuth.isAuthenticated = false;
        r("ok");
      });
    });
  },
};

export default mailAuth;
